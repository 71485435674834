.breadcrumbs {
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 0px 8px;
    font-family: Poppins;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    width: 15%;
    /* Inside Auto Layout */
    text-align: center;
    padding: 4px 10px;
    margin-top: 0.4%;
    position: absolute;
    top: 10%;
}

.breadcrumbs-exhibition-detail {
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 0px 8px;
    font-family: Poppins;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    width: 25%;
    /* Inside Auto Layout */
    margin: 0px 10px;
    text-align: center;
    padding: 4px 10px;
    margin-top: 1%;
    position: absolute;
    top: 10%;
}

.breadcrumbs .with-link,
.breadcrumbs-exhibition-detail .with-link {
    color: #b42b2d;
    cursor: pointer;
}

.breadcrumbs .slash,
.breadcrumbs-exhibition-detail .slash {
    color: #b42b2d;
}

.navigation-left-exhibition-detail {
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 0px 8px;
    font-family: Poppins;
    font-style: normal;
    font-size: 14px;
    line-height: 31px;
    width: 10%;
    /* Inside Auto Layout */
    margin: 0px 10px;
    text-align: center;
    padding: 4px 10px;
    margin-top: 4%;
    position: absolute;
    border: 1px solid #b42b2d;
    box-sizing: border-box;
    border-radius: 20px;
    color: #b42b2d;
    cursor: pointer;
    transition: ease-in;
    top: 10%;
}
.navigation-left {
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 0px 8px;
    font-family: Poppins;
    font-style: normal;
    font-size: 14px;
    line-height: 31px;
    width: 10%;
    text-align: center;
    padding: 4px 10px;
    margin-top: 3%;
    position: absolute;
    border: 1px solid #b42b2d;
    box-sizing: border-box;
    border-radius: 20px;
    color: #b42b2d;
    cursor: pointer;
    transition: ease-in;
    top: 10%;
}

.navigation-left:hover {
    background: #b42b2d;
    color: #ffff;
}

.navigation-right {
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 0px 8px;
    font-family: Poppins;
    font-style: normal;
    font-size: 14px;
    line-height: 31px;
    width: 10%;
    /* Inside Auto Layout */
    margin: 0px 10px;
    text-align: center;
    padding: 4px 10px;
    margin-top: 3%;
    position: absolute;
    right: 0;
    border: 1px solid #b42b2d;
    box-sizing: border-box;
    border-radius: 20px;
    color: #b42b2d;
    cursor: pointer;
    transition: ease-in;
    top: 10%;
}

.navigation-right:hover {
    background: #b42b2d;
    color: #ffff;
}

//

.Flower1 {
    position: absolute;
    top: 63%;
    width: 3%;
    left: 15%;
    transform: rotateY(180deg);
}

.Flower2 {
    position: absolute;
    top: 63%;
    width: 3%;
    left: 33%;
}

.Flower3 {
    position: absolute;
    top: 63%;
    width: 3%;
    left: 51%;
    transform: rotateY(180deg);
}

.Flower4 {
    position: absolute;
    top: 63%;
    width: 3%;
    left: 70%;
}

.Flower5 {
    position: absolute;
    top: 83%;
    width: 4%;
    left: 16%;
}

.Flower6 {
    position: absolute;
    top: 85%;
    width: 4%;
    right: 13%;
    transform: rotateY(180deg);
}

.ExhibitionAnimationGroupOne {
    transform: rotateY(180deg);
    position: absolute;
    top: 70%;
    width: 4%;
    right: 40%;
}
.ExhibitionAnimationGroupTwo {
    position: absolute;
    top: 70%;
    width: 8%;
    left: 40%;
}

.ExhibitionAnimationGroupThree {
    position: absolute;
    top: 70%;
    width: 4%;
    left: 10%;
}

.ExhibitionAnimationGroupThree {
    position: absolute;
    top: 60%;
    width: 4%;
    left: 14%;
}
.ExhibitionAnimationGroupFour {
    position: absolute;
    top: 82%;
    width: 6%;
    left: 10%;
}

.ExhibitionAnimationGroupFive {
    position: absolute;
    top: 62%;
    width: 4%;
    right: 23%;
    transform: rotateY(180deg);
}
