.centering {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    top: 30%;
    position: absolute;
}

.centering img {
    width: 20%;
}
