.title-with-close-modal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    cursor: pointer;
    z-index: 99999999;
}

.title-with-close-modal .title,
.title-with-close-modal .close {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}

.modal-two-layout-body {
    width: 100%;
    display: flex;
    flex-direction: row;
}
.modal-two-layout-sidebar {
    width: 30%;
    min-height: 100%;
    text-align: left;
    overflow-y: scroll !important;
    height: 90vh;
}

.lihat {
    color: #b42b2d;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-two-layout-sidebar-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 12px;
    border-bottom: 1px solid #f2f2f2;
    padding: 9px 9px;
    cursor: pointer;
}

.modal-two-layout-content {
    width: 70%;
    min-height: 100%;
    padding: 10px;
    text-align: left;
}
