@media only screen and (min-device-width: 400px) and (max-device-width: 480px) {
    .container-footer-mobile {
        height: 55px;
        width: 100vw;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: row;
        align-items: center;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        justify-content: center;
        bottom: 0;
        position: absolute;
        box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    }

    .container-footer-mobile .item {
        padding: 10px;
        z-index: 99999999999;
    }

    .container-footer-mobile .item-left .pengunjung {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .container-footer-mobile .item .sound-container {
        justify-content: center;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .container-footer-mobile .item .sound-container .sound {
        margin-right: 4px;
    }

    .container-footer-mobile .item-left .visitor {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 27px;
        color: #b42b2d;
    }

    .container-footer-mobile .item-right .kontak-kami {
        background: #ffffff;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        border-radius: 20px;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 0px 8px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #b42b2d;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 10px;
        padding: 4px 10px;
        cursor: pointer;
    }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 319px) {
    .container-footer-mobile {
        height: 50px;
        width: 100vw;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(110, 74, 74, 0.25);
        display: flex;
        flex-direction: row;
        align-items: center;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        justify-content: center;
        bottom: 0;
        position: absolute;
        box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    }

    .container-footer-mobile .item {
        padding: 8px;
        z-index: 99999999999;
    }

    .container-footer-mobile .item-left .pengunjung {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 21px;
    }

    .container-footer-mobile .item .sound-container {
        justify-content: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 11px;
    }

    .container-footer-mobile .item .sound-container .sound {
        margin-right: 4px;
    }

    .container-footer-mobile .item-left .visitor {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 27px;
        color: #b42b2d;
    }

    .container-footer-mobile .item-right .kontak-kami {
        background: #ffffff;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        border-radius: 20px;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 0px 8px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 21px;
        text-align: center;
        color: #b42b2d;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 6px;
        padding: 4px 10px;
        cursor: pointer;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 400px) {
    .container-footer-mobile {
        height: 55px;
        width: 100vw;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: row;
        align-items: center;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        justify-content: center;
        bottom: 0;
        position: absolute;
        box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    }

    .container-footer-mobile .item {
        padding: 8px;
        z-index: 99999999999;
    }

    .container-footer-mobile .item-left .pengunjung {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .container-footer-mobile .item .sound-container {
        justify-content: center;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .container-footer-mobile .item .sound-container .sound {
        margin-right: 4px;
    }

    .container-footer-mobile .item-left .visitor {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 27px;
        color: #b42b2d;
    }

    .container-footer-mobile .item-right .kontak-kami {
        background: #ffffff;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        border-radius: 20px;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 0px 8px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #b42b2d;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 10px;
        padding: 4px 10px;
        cursor: pointer;
    }
}

@media only screen and (min-device-width: 540px) and (max-device-width: 766px) {
    .container-footer-mobile {
        height: 55px;
        width: 100vw;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: row;
        align-items: center;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        justify-content: center;
        bottom: 0;
        position: absolute;
        box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    }

    .container-footer-mobile .item {
        padding: 8px;
        z-index: 99999999999;
    }

    .container-footer-mobile .item-left .pengunjung {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        margin-right: 50px;
    }

    .container-footer-mobile .item .sound-container {
        justify-content: center;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .container-footer-mobile .item .sound-container .sound {
        margin-right: 4px;
    }

    .container-footer-mobile .item-left .visitor {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 27px;
        color: #b42b2d;
    }

    .container-footer-mobile .item-right .kontak-kami {
        background: #ffffff;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        border-radius: 20px;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 0px 8px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #b42b2d;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 10px;
        padding: 5px 20px;
        cursor: pointer;
        margin-left: 50px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
    .container-footer-mobile {
        height: 55px;
        width: 100vw;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: row;
        align-items: center;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        justify-content: center;
        bottom: 0;
        position: absolute;
        box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    }

    .container-footer-mobile .item {
        padding: 8px;
        z-index: 99999999999;
    }

    .container-footer-mobile .item-left .pengunjung {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        margin-right: 50px;
    }

    .container-footer-mobile .item .sound-container {
        justify-content: center;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .container-footer-mobile .item .sound-container .sound {
        margin-right: 4px;
    }

    .container-footer-mobile .item-left .visitor {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 27px;
        color: #b42b2d;
    }

    .container-footer-mobile .item-right .kontak-kami {
        background: #ffffff;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        border-radius: 20px;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 0px 8px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #b42b2d;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 10px;
        padding: 10px 20px;
        cursor: pointer;
        margin-left: 50px;
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
    .container-footer-mobile {
        height: 75px;
        width: 100vw;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: row;
        align-items: center;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        justify-content: center;
        bottom: 0;
        position: absolute;
        box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    }

    .container-footer-mobile .item {
        padding: 8px;
        z-index: 99999999999;
    }

    .container-footer-mobile .item-left .pengunjung {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 21px;
        margin-right: 50px;
    }

    .container-footer-mobile .item .sound-container {
        justify-content: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 24px;
    }

    .container-footer-mobile .item .sound-container .sound {
        margin-right: 12px;
        width: 32px;
    }

    .container-footer-mobile .item-left .visitor {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 27px;
        color: #b42b2d;
    }

    .container-footer-mobile .item-right .kontak-kami {
        background: #ffffff;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        border-radius: 20px;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin: 0px 8px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 21px;
        text-align: center;
        color: #b42b2d;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 10px;
        padding: 10px 20px;
        cursor: pointer;
        margin-left: 50px;
    }
}
