@media only screen and (min-device-width: 280px) and (max-device-width: 319px) {
    .zm-button-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 20%;
    }

    .zm-button-eh {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 35%;
    }

    .zm-button-daftar-video {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        bottom: 40%;
    }

    .zoominar-container-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 60%;
        .video-playlist {
            background: rgba(255, 255, 255, 0.75);
            box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
            border-radius: 2.75px;
            width: 100%;
            .playlist-title {
                font-family: Poppins;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: #000000;
                background: #ffffff;
                box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
                padding: 3px 14px;
            }
            .playlist-video {
                overflow-y: auto;
                padding: 0px 8px;
                max-height: 70vh;
                &::-webkit-scrollbar {
                    width: 4px;
                }
                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }
                &::-webkit-scrollbar-thumb {
                    background: #888;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
                .video-item {
                    background: #ffffff;
                    padding: 0px 8px;
                    margin-top: 8px;
                    .video-title {
                        font-size: 11px;
                        line-height: 13px;
                        padding: 4px 0px;
                    }
                    .video-img {
                        img {
                            width: 100%;
                        }
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                .video-item.active {
                    border: 1px solid #b42b2d;
                }
            }
        }
        .zoominar-content {
            width: 100%;
            max-width: 720px;
            #video-frame {
                position: relative;
                background: #ffffff;
                box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                height: 0px;
                width: 95%;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                padding-top: 50%;
                .video-frame-wrapper {
                    position: absolute;
                    top: 12px;
                    left: 12px;
                    height: 100%;
                    width: 100%;
                    iframe {
                        width: 93%;
                        height: 89%;
                    }
                }
            }
        }
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 359px) {
    .zm-button-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 20%;
    }

    .zm-button-eh {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 35%;
    }

    .zm-button-daftar-video {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        bottom: 20%;
    }

    .zoominar-container-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 60%;
        .video-playlist {
            background: rgba(255, 255, 255, 0.75);
            box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
            border-radius: 2.75px;
            width: 100%;
            .playlist-title {
                font-family: Poppins;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: #000000;
                background: #ffffff;
                box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
                padding: 3px 14px;
            }
            .playlist-video {
                overflow-y: auto;
                padding: 0px 8px;
                max-height: 70vh;
                &::-webkit-scrollbar {
                    width: 4px;
                }
                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }
                &::-webkit-scrollbar-thumb {
                    background: #888;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
                .video-item {
                    background: #ffffff;
                    padding: 0px 8px;
                    margin-top: 8px;
                    .video-title {
                        font-size: 11px;
                        line-height: 13px;
                        padding: 4px 0px;
                    }
                    .video-img {
                        img {
                            width: 100%;
                        }
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                .video-item.active {
                    border: 1px solid #b42b2d;
                }
            }
        }
        .zoominar-content {
            width: 100%;
            max-width: 720px;
            #video-frame {
                position: relative;
                background: #ffffff;
                box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                height: 0px;
                width: 95%;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                padding-top: 50%;
                .video-frame-wrapper {
                    position: absolute;
                    top: 12px;
                    left: 12px;
                    height: 100%;
                    width: 100%;
                    iframe {
                        width: 93%;
                        height: 89%;
                    }
                }
            }
        }
    }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 399px) {
    .zm-button-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 20%;
    }

    .zm-button-eh {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 35%;
    }

    .zm-button-daftar-video {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        bottom: 35%;
    }

    .zoominar-container-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 50%;
        .video-playlist {
            background: rgba(255, 255, 255, 0.75);
            box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
            border-radius: 2.75px;
            width: 100%;
            .playlist-title {
                font-family: Poppins;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: #000000;
                background: #ffffff;
                box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
                padding: 3px 14px;
            }
            .playlist-video {
                overflow-y: auto;
                padding: 0px 8px;
                max-height: 70vh;
                &::-webkit-scrollbar {
                    width: 4px;
                }
                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }
                &::-webkit-scrollbar-thumb {
                    background: #888;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
                .video-item {
                    background: #ffffff;
                    padding: 0px 8px;
                    margin-top: 8px;
                    .video-title {
                        font-size: 11px;
                        line-height: 13px;
                        padding: 4px 0px;
                    }
                    .video-img {
                        img {
                            width: 100%;
                        }
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                .video-item.active {
                    border: 1px solid #b42b2d;
                }
            }
        }
        .zoominar-content {
            width: 100%;
            max-width: 720px;
            #video-frame {
                position: relative;
                background: #ffffff;
                box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                height: 0px;
                width: 95%;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                padding-top: 50%;
                .video-frame-wrapper {
                    position: absolute;
                    top: 12px;
                    left: 12px;
                    height: 100%;
                    width: 100%;
                    iframe {
                        width: 93%;
                        height: 89%;
                    }
                }
            }
        }
    }
}

@media only screen and (min-device-width: 400px) and (max-device-width: 430px) {
    .zm-button-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 20%;
    }

    .zm-button-eh {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 35%;
    }

    .zm-button-daftar-video {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        bottom: 30%;
    }

    .zoominar-container-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 60%;
        .video-playlist {
            background: rgba(255, 255, 255, 0.75);
            box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
            border-radius: 2.75px;
            width: 100%;
            .playlist-title {
                font-family: Poppins;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: #000000;
                background: #ffffff;
                box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
                padding: 3px 14px;
            }
            .playlist-video {
                overflow-y: auto;
                padding: 0px 8px;
                max-height: 70vh;
                &::-webkit-scrollbar {
                    width: 4px;
                }
                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }
                &::-webkit-scrollbar-thumb {
                    background: #888;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
                .video-item {
                    background: #ffffff;
                    padding: 0px 8px;
                    margin-top: 8px;
                    .video-title {
                        font-size: 11px;
                        line-height: 13px;
                        padding: 4px 0px;
                    }
                    .video-img {
                        img {
                            width: 100%;
                        }
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                .video-item.active {
                    border: 1px solid #b42b2d;
                }
            }
        }
        .zoominar-content {
            width: 100%;
            max-width: 720px;
            #video-frame {
                position: relative;
                background: #ffffff;
                box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                height: 0px;
                width: 95%;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                padding-top: 50%;
                .video-frame-wrapper {
                    position: absolute;
                    top: 12px;
                    left: 12px;
                    height: 100%;
                    width: 100%;
                    iframe {
                        width: 93%;
                        height: 89%;
                    }
                }
            }
        }
    }
}

// IPHONE X
@media only screen and (device-width: 375px) and (min-device-height: 800px) {
    .zm-button-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 12px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 20%;
    }

    .zm-button-eh {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 12px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 35%;
    }

    .zm-button-daftar-video {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 12px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        bottom: 35%;
    }

    .zoominar-container-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 60%;
        .video-playlist {
            background: rgba(255, 255, 255, 0.75);
            box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
            border-radius: 2.75px;
            width: 100%;
            .playlist-title {
                font-family: Poppins;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: #000000;
                background: #ffffff;
                box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
                padding: 3px 14px;
            }
            .playlist-video {
                overflow-y: auto;
                padding: 0px 8px;
                max-height: 70vh;
                &::-webkit-scrollbar {
                    width: 4px;
                }
                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }
                &::-webkit-scrollbar-thumb {
                    background: #888;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
                .video-item {
                    background: #ffffff;
                    padding: 0px 8px;
                    margin-top: 8px;
                    .video-title {
                        font-size: 11px;
                        line-height: 13px;
                        padding: 4px 0px;
                    }
                    .video-img {
                        img {
                            width: 100%;
                        }
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                .video-item.active {
                    border: 1px solid #b42b2d;
                }
            }
        }
        .zoominar-content {
            width: 100%;
            max-width: 720px;
            #video-frame {
                position: relative;
                background: #ffffff;
                box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                height: 0px;
                width: 95%;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                padding-top: 50%;
                .video-frame-wrapper {
                    position: absolute;
                    top: 12px;
                    left: 12px;
                    height: 100%;
                    width: 100%;
                    iframe {
                        width: 93%;
                        height: 89%;
                    }
                }
            }
        }
    }
}

@media only screen and (min-device-width: 431px) and (max-device-width: 539px) {
    .zm-button-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 20%;
    }

    .zm-button-eh {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 35%;
    }

    .zm-button-daftar-video {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        bottom: 20%;
    }

    .zoominar-container-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 50%;
        .video-playlist {
            background: rgba(255, 255, 255, 0.75);
            box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
            border-radius: 2.75px;
            width: 100%;
            .playlist-title {
                font-family: Poppins;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: #000000;
                background: #ffffff;
                box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
                padding: 3px 14px;
            }
            .playlist-video {
                overflow-y: auto;
                padding: 0px 8px;
                max-height: 70vh;
                &::-webkit-scrollbar {
                    width: 4px;
                }
                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }
                &::-webkit-scrollbar-thumb {
                    background: #888;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
                .video-item {
                    background: #ffffff;
                    padding: 0px 8px;
                    margin-top: 8px;
                    .video-title {
                        font-size: 11px;
                        line-height: 13px;
                        padding: 4px 0px;
                    }
                    .video-img {
                        img {
                            width: 100%;
                        }
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                .video-item.active {
                    border: 1px solid #b42b2d;
                }
            }
        }
        .zoominar-content {
            width: 100%;
            max-width: 720px;
            #video-frame {
                position: relative;
                background: #ffffff;
                box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                height: 0px;
                width: 95%;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                padding-top: 50%;
                .video-frame-wrapper {
                    position: absolute;
                    top: 12px;
                    left: 12px;
                    height: 100%;
                    width: 100%;
                    iframe {
                        width: 93%;
                        height: 89%;
                    }
                }
            }
        }
    }
}

@media only screen and (min-device-width: 540px) and (max-device-width: 768px) {
    .zm-button-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 20%;
    }

    .zm-button-eh {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 35%;
    }

    .zm-button-daftar-video {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        bottom: 15%;
    }

    .zoominar-container-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 50%;
        .video-playlist {
            background: rgba(255, 255, 255, 0.75);
            box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
            border-radius: 2.75px;
            width: 100%;
            .playlist-title {
                font-family: Poppins;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: #000000;
                background: #ffffff;
                box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
                padding: 3px 14px;
            }
            .playlist-video {
                overflow-y: auto;
                padding: 0px 8px;
                max-height: 70vh;
                &::-webkit-scrollbar {
                    width: 4px;
                }
                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }
                &::-webkit-scrollbar-thumb {
                    background: #888;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
                .video-item {
                    background: #ffffff;
                    padding: 0px 8px;
                    margin-top: 8px;
                    .video-title {
                        font-size: 11px;
                        line-height: 13px;
                        padding: 4px 0px;
                    }
                    .video-img {
                        img {
                            width: 100%;
                        }
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                .video-item.active {
                    border: 1px solid #b42b2d;
                }
            }
        }
        .zoominar-content {
            width: 100%;
            max-width: 720px;
            #video-frame {
                position: relative;
                background: #ffffff;
                box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                height: 0px;
                width: 95%;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                padding-top: 50%;
                .video-frame-wrapper {
                    position: absolute;
                    top: 12px;
                    left: 12px;
                    height: 100%;
                    width: 100%;
                    iframe {
                        width: 93%;
                        height: 89%;
                    }
                }
            }
        }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .zm-button-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 35%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 24px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 20%;
    }

    .zm-button-eh {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 35%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 24px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 35%;
    }

    .zm-button-daftar-video {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 35%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 24px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        bottom: 15%;
    }

    .zoominar-container-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 50%;
        .video-playlist {
            background: rgba(255, 255, 255, 0.75);
            box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
            border-radius: 2.75px;
            width: 100%;
            .playlist-title {
                font-family: Poppins;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: #000000;
                background: #ffffff;
                box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
                padding: 3px 14px;
            }
            .playlist-video {
                overflow-y: auto;
                padding: 0px 8px;
                max-height: 70vh;
                &::-webkit-scrollbar {
                    width: 4px;
                }
                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }
                &::-webkit-scrollbar-thumb {
                    background: #888;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
                .video-item {
                    background: #ffffff;
                    padding: 0px 8px;
                    margin-top: 8px;
                    .video-title {
                        font-size: 11px;
                        line-height: 13px;
                        padding: 4px 0px;
                    }
                    .video-img {
                        img {
                            width: 100%;
                        }
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                .video-item.active {
                    border: 1px solid #b42b2d;
                }
            }
        }
        .zoominar-content {
            width: 100%;
            max-width: 720px;
            #video-frame {
                position: absolute;
                background: #ffffff;
                box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                height: 0px;
                width: 95%;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                padding-top: 50%;
                .video-frame-wrapper {
                    position: absolute;
                    top: 12px;
                    left: 12px;
                    height: 100%;
                    width: 100%;
                    iframe {
                        width: 93%;
                        height: 89%;
                    }
                }
            }
        }
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
    .zm-button-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 35%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 24px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 20px;
        color: #b42b2d;
        position: absolute;
        margin-top: 20%;
    }

    .zm-button-eh {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 35%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 24px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 20px;
        color: #b42b2d;
        position: absolute;
        margin-top: 35%;
    }

    .zm-button-daftar-video {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 35%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 24px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 20px;
        color: #b42b2d;
        position: absolute;
        bottom: 15%;
    }

    .zoominar-container-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 50%;
        .video-playlist {
            background: rgba(255, 255, 255, 0.75);
            box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
            border-radius: 2.75px;
            width: 100%;
            .playlist-title {
                font-family: Poppins;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: #000000;
                background: #ffffff;
                box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
                padding: 3px 14px;
            }
            .playlist-video {
                overflow-y: auto;
                padding: 0px 8px;
                max-height: 70vh;
                &::-webkit-scrollbar {
                    width: 4px;
                }
                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }
                &::-webkit-scrollbar-thumb {
                    background: #888;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
                .video-item {
                    background: #ffffff;
                    padding: 0px 8px;
                    margin-top: 8px;
                    .video-title {
                        font-size: 11px;
                        line-height: 13px;
                        padding: 4px 0px;
                    }
                    .video-img {
                        img {
                            width: 100%;
                        }
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                .video-item.active {
                    border: 1px solid #b42b2d;
                }
            }
        }
        .zoominar-content {
            width: 100%;
            max-width: 720px;
            #video-frame {
                position: absolute;
                background: #ffffff;
                box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                height: 0px;
                width: 95%;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                padding-top: 50%;
                .video-frame-wrapper {
                    position: absolute;
                    top: 12px;
                    left: 12px;
                    height: 100%;
                    width: 100%;
                    iframe {
                        width: 93%;
                        height: 89%;
                    }
                }
            }
        }
    }
}
