.centering-action-button-zoominar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    bottom: 6%;
    position: absolute;
    margin: auto;
    width: 100%;
    cursor: pointer;
}

.action {
    padding: 5px;
}
