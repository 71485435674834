.container-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.bukutamuwebsite {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: #ffffff;
    /* Pan RB Primary */
    border: 1px solid #b42b2d;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    cursor: pointer !important;
    width: 141px;
    height: 40px;
    color: #b42b2d;
}
