$white: #ffffff;
$title-color: #47403e;
$grey: #f6f6f6;
$primary: #b42b2d;
$primary-disabled: #f89092;
$border-color: #c2c2c2;

.exhibition-hall {
    background-image: url(../../assets/img/background-exhibition.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh !important;
}

.exhibition-hall-detail {
    background-image: url(../../assets/img/background-exhibition-hall-detail.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

.exhibition-hall-mobile {
    background-image: url(../../assets/img/background-exhibition-mobile.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh !important;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
    font-family: "Poppins", sans-serif;
}

.container-exhibition {
    top: 48%;
    position: relative;
}
.backdrop {
    bottom: 0px;
}

.list-exhibition {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.list-image img {
    display: block;
    margin: auto;
}

.list-active {
    background-color: red;
    border-radius: 50%;
    content: "";
}

.backdrop-non-active {
    display: hidden;
}

.centering-row {
    justify-content: center;
    align-items: center;
    display: flex;
}

.list-empat {
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
}

.list-empat .item-exhibition-hall-top img {
    width: 70%;
}

.list-empat .item-exhibition-hall-top-name img {
    width: 100%;
}

.list-dua .item-exhibition-hall-bottom-name img {
    width: 100%;
}

.item-exhibition-hall-top {
    margin-top: 26%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.item-exhibition-hall-top-name {
    position: absolute;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: $primary;
    padding: 4px;
    cursor: pointer;
    background-color: #fff;
    width: 5%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.item-exhibition-hall-bottom-name img {
}

.item-exhibition-hall-top-description {
    margin-top: -14%;
    position: absolute;
    background-color: white;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: $primary;
    padding: 4px;
}

.item-exhibition-hall-top-name:hover + .item-exhibition-hall-top-description-hidden {
    margin-top: -10%;
    position: absolute;
    background-color: white;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: $primary;
    padding: 4px;
    display: block;
    content: "";
}

.item-exhibition-hall-top-description-hidden {
    display: none;
}

.item-exhibition-hall-bottom-description-hidden {
    display: none;
}

// list 2
.list-dua {
    justify-content: space-around;
    align-items: center;
    display: flex;
    text-align: center;
    margin-top: 2%;
}

.list-empat .item-exhibition-hall-bottom img {
    width: 80%;
}

.item-exhibition-hall-bottom {
    margin-top: -2%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.item-exhibition-hall-bottom-description {
    margin-top: -14%;
    position: absolute;
    background-color: white;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: $primary;
    padding: 4px;
}

.item-exhibition-hall-bottom-name:hover + .item-exhibition-hall-bottom-description-hidden {
    margin-top: -10%;
    position: absolute;
    background-color: white;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: $primary;
    padding: 4px;
    display: block;
    content: "";
}

.item-exhibition-hall-bottom-name {
    position: absolute;

    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: $primary;
    padding: 4px;
    cursor: pointer;
    background-color: #fff;
    width: 8%;
    margin-top: 1%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.centering-exhibition-hall {
    text-align: center;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    position: absolute;
    bottom: 0;
}

//  detail

.detail-exhibition-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.4%;

    position: absolute;
    left: 51.65%;
    right: 23.61%;
    top: 25%;

    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    line-height: 18px;

    /* Pan RB Primary */

    color: #b42b2d;
}

.detail-exhibition-name-logo {
    width: 30%;
}

.detail-exhibition-description {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 38%;
    width: 40vw;

    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
}

.list-exhibition-website {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    position: absolute;
    bottom: 2%;
}

.list-exhibition-mobile {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    position: absolute;
    bottom: 15%;
}

.bottom-container-exhibition-detail {
    display: flex;
    flex-direction: row;
    width: 47.7%;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    height: 8%;
    position: absolute;
    bottom: 8%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    justify-content: space-evenly;
    align-items: center;
}

.arrow-navigation {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: absolute;
    bottom: 35%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    align-items: center;
}

.arrow-navigation .slider-left {
    margin-left: 8.3%;
    width: 46px;
    height: 46px;
    cursor: pointer;
}

.arrow-navigation .slider-right {
    margin-right: 8.3%;
    width: 46px;
    height: 46px;
    cursor: pointer;
}

.info-exhibition-detail {
    width: 33%;
    padding: 4px;
    text-align: center;
}

.icon-zoom {
    height: 14px;
    margin-right: 4px;
}
.info-button-zoom {
    border: 1px solid #b42b2d;
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 12px;
    height: 32px;
    cursor: pointer;
    /* identical to box height */
    text-align: center;
    /* Blue 1 */
    color: #2f80ed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.info-button-tanya {
    border: 1px solid #b42b2d;
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 12px;
    cursor: pointer;
    height: 32px;
    /* identical to box height */
    text-align: center;
    /* Blue 1 */
    color: #b42b2d;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.info-exhibition-detail .info {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 14px;
    display: flex;
    text-align: left;
    margin-left: 8px;
}
.list-slider-website {
    margin: 5px;
    display: flex;
    bottom: 0%;
    z-index: 999;
    height: 20px;
    width: 20px;
    background-color: #b42b2d;
    border-radius: 10px;
    cursor: pointer;
}
.list-slider-website:hover {
    background-color: red;
}
.list-slider-website-non:hover {
    background-color: red;
}

.list-slider-website-non {
    margin: 5px;
    display: flex;
    bottom: 0%;
    z-index: 999;
    height: 20px;
    width: 20px;
    background-color: #bdbdbd;
    border-radius: 10px;
    cursor: pointer;
}

.list-slider-mobile {
    margin: 5px;
    display: flex;
    bottom: 0%;
    z-index: 999;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;
}
.list-slider-mobile:hover {
    background-color: red;
}
.list-slider-mobile-non:hover {
    background-color: red;
}

.list-slider-mobile-non {
    margin: 5px;
    display: flex;
    bottom: 0%;
    z-index: 999;
    height: 20px;
    width: 20px;
    background-color: #bdbdbd;
    border-radius: 10px;
    cursor: pointer;
}

.container-poster {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: center;
    margin-top: 22%;
    position: absolute;
    width: 100vw;
}
.poster-box {
    width: 12%;

    background-color: white;
    margin: 20px;
    border: 3px solid #333333;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    align-content: space-around;
    gap: 10% 0px;
}

.poster-box .name {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    margin-top: 10%;

    text-align: center;

    /* Pan RB Primary */

    color: $primary;
}

.poster-box .icon {
    width: 70.78px;
    padding: 25px 0px;
}

.poster-logo-bottom {
    width: 70%;
}

.booth-mobile {
}

//@at-root

.container-exhibition-detail-booth-detail {
    background: white;
    display: flex;
    flex-direction: column;
    height: 95vh;
    padding-bottom: 10px;
    overflow-y: scroll;
    height: 95vh;
}

.container-exhibition-detail-booth-detail .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    padding: 0px 10px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999999;
}

.container-exhibition-detail-booth-detail .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background-color: $white;
    padding: 0px 10px;
    top: 68px;
    position: relative;
    font-size: 12px;
    font-family: Poppins;
    line-height: 21px;
}

.playingVideoOnBoothModal {
    background: #fff;
    /* Pan RB Primary */
    border: 1px solid #b42b2d;
    box-sizing: border-box;
    border-radius: 20px;
    color: #b42b2d;
    height: 32px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
}
.playingVideoOnBoothModalMobile {
    background: #fff;
    /* Pan RB Primary */
    border: 1px solid #b42b2d;
    box-sizing: border-box;
    border-radius: 20px;
    color: #b42b2d;
    height: 46px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    z-index: 999999;
    margin-top: 10px;
}

.bergabung-di-zoom {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    height: 46px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    color: #2f80ed;
    z-index: 9999;
}

html {
    overflow-y: visible;
}
body {
    overflow-y: visible;
    position: relative;
    margin: 0;
    padding: 0;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 460px) {
    .header-name {
        font-size: 12px;
        font-weight: bold;
        padding-left: 20px;
    }
    .header-close {
        font-size: 12px;
        font-weight: bold;
        margin-right: 30px;
    }
    .content .list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 12px;
        line-height: 24px;
        border-bottom: 1px solid #f2f2f2;
        z-index: 99999;
    }

    .content .list .item {
        padding: 10px 20px 10px 10px;
    }

    .content .list .action {
        color: #b42b2d;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
    .header-name {
        font-size: 24px;
        font-weight: bold;
        padding-left: 20px;
    }
    .header-close {
        font-size: 24px;
        font-weight: bold;
        margin-right: 30px;
    }
    .content .list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 24px;
        line-height: 36px;
        border-bottom: 1px solid #f2f2f2;
        z-index: 99999;
    }

    .content .list .item {
        padding: 20px 30px 20px 20px;
    }

    .content .list .action {
        color: #b42b2d;
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
    .header-name {
        font-size: 24px;
        font-weight: bold;
    }
    .header-close {
        font-size: 24px;
        font-weight: bold;
        margin-right: 30px;
    }
    .content .list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 24px;
        line-height: 36px;
        border-bottom: 1px solid #f2f2f2;
        z-index: 99999;
    }

    .content .list .item {
        padding: 20px 30px 20px 20px;
    }

    .content .list .action {
        color: #b42b2d;
    }
}
