@media only screen and (min-device-width: 280px) and (max-device-width: 320px) {
    .container-center-mobile {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 20%;
        text-align: center;
    }

    .container-center-mobile-logo-exhibition {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 26%;
        text-align: center;
        background-color: #fff;
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        padding: 5px 0px;
    }

    .logo-instansi {
        width: 100%;
    }

    .container-center-mobile-description {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 40%;
        text-align: center;

        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;

        /* Pan RB Primary */

        color: #b42b2d;
        background-color: #fff;
        width: 60%;
        padding: 5px;
        border-radius: 10px;
    }

    .arrow-navigation-exhibition-mobile {
        display: flex;
        flex-direction: row;
        width: 100%;
        position: absolute;
        bottom: 30%;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        align-items: center;
    }

    .arrow-navigation-exhibition-mobile .slider-left {
        width: 46px;
        height: 46px;
        cursor: pointer;
    }

    .arrow-navigation-exhibition-mobile .slider-right {
        width: 46px;
        height: 46px;
        cursor: pointer;
    }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 400px) {
    .container-center-mobile {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 20%;
        text-align: center;
    }

    .container-center-mobile-logo-exhibition {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 26%;
        text-align: center;
        background-color: #fff;
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        padding: 5px 0px;
    }

    .logo-instansi {
        width: 100%;
    }

    .container-center-mobile-description {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 40%;
        text-align: center;

        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;

        /* Pan RB Primary */

        color: #b42b2d;
        background-color: #fff;
        width: 60%;
        padding: 5px;
        border-radius: 10px;
    }

    .arrow-navigation-exhibition-mobile {
        display: flex;
        flex-direction: row;
        width: 100%;
        position: absolute;
        bottom: 30%;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        align-items: center;
    }

    .arrow-navigation-exhibition-mobile .slider-left {
        width: 46px;
        height: 46px;
        cursor: pointer;
    }

    .arrow-navigation-exhibition-mobile .slider-right {
        width: 46px;
        height: 46px;
        cursor: pointer;
    }
}

// IPHONE X
@media only screen and (device-width: 375px) and (min-device-height: 800px) {
    .container-center-mobile {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 20%;
        text-align: center;
    }

    .container-center-mobile-logo-exhibition {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 26%;
        text-align: center;
        background-color: #fff;
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        padding: 5px 0px;
    }

    .logo-instansi {
        width: 100%;
    }

    .container-center-mobile-description {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 40%;
        text-align: center;

        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;

        /* Pan RB Primary */

        color: #b42b2d;
        background-color: #fff;
        width: 60%;
        padding: 5px;
        border-radius: 10px;
    }

    .arrow-navigation-exhibition-mobile {
        display: flex;
        flex-direction: row;
        width: 100%;
        position: absolute;
        bottom: 30%;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        align-items: center;
    }

    .arrow-navigation-exhibition-mobile .slider-left {
        width: 46px;
        height: 46px;
        cursor: pointer;
    }

    .arrow-navigation-exhibition-mobile .slider-right {
        width: 46px;
        height: 46px;
        cursor: pointer;
    }
}

@media only screen and (min-device-width: 400px) and (max-device-width: 430px) {
    .container-center-mobile {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 20%;
        text-align: center;
    }

    .container-center-mobile-logo-exhibition {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 26%;
        text-align: center;
        background-color: #fff;
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        padding: 5px 0px;
    }

    .logo-instansi {
        width: 100%;
    }

    .container-center-mobile-description {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 40%;
        text-align: center;

        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;

        /* Pan RB Primary */

        color: #b42b2d;
        background-color: #fff;
        width: 60%;
        padding: 5px;
        border-radius: 10px;
    }

    .arrow-navigation-exhibition-mobile {
        display: flex;
        flex-direction: row;
        width: 100%;
        position: absolute;
        bottom: 30%;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        align-items: center;
    }

    .arrow-navigation-exhibition-mobile .slider-left {
        width: 46px;
        height: 46px;
        cursor: pointer;
    }

    .arrow-navigation-exhibition-mobile .slider-right {
        width: 46px;
        height: 46px;
        cursor: pointer;
    }
}

@media only screen and (min-device-width: 431px) and (max-device-width: 540px) {
    .container-center-mobile {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 10%;
        text-align: center;
    }

    .container-center-mobile-logo-exhibition {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 19%;
        text-align: center;
        background-color: #fff;
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        padding: 5px 0px;
    }

    .logo-instansi {
        width: 100%;
    }

    .container-center-mobile-description {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 45%;
        text-align: center;

        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        text-align: center;

        /* Pan RB Primary */

        color: #b42b2d;
        background-color: #fff;
        width: 60%;
        padding: 5px;
        border-radius: 10px;
    }

    .arrow-navigation-exhibition-mobile {
        display: flex;
        flex-direction: row;
        width: 100%;
        position: absolute;
        bottom: 25%;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        align-items: center;
    }

    .arrow-navigation-exhibition-mobile .slider-left {
        width: 46px;
        height: 46px;
        cursor: pointer;
    }

    .arrow-navigation-exhibition-mobile .slider-right {
        width: 46px;
        height: 46px;
        cursor: pointer;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .container-center-mobile {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 10%;
        text-align: center;
    }

    .container-center-mobile-logo-exhibition {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 19%;
        text-align: center;
        background-color: #fff;
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        padding: 5px 0px;
    }

    .logo-instansi {
        width: 100%;
    }

    .container-center-mobile-description {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 45%;
        text-align: center;

        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 48px;
        text-align: center;

        /* Pan RB Primary */

        color: #b42b2d;
        background-color: #fff;
        width: 60%;
        padding: 5px;
        border-radius: 10px;
    }

    .arrow-navigation-exhibition-mobile {
        display: flex;
        flex-direction: row;
        width: 100%;
        position: absolute;
        bottom: 25%;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        align-items: center;
    }

    .arrow-navigation-exhibition-mobile .slider-left {
        width: 46px;
        height: 46px;
        cursor: pointer;
    }

    .arrow-navigation-exhibition-mobile .slider-right {
        width: 46px;
        height: 46px;
        cursor: pointer;
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
    .container-center-mobile {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 10%;
        text-align: center;
    }

    .container-center-mobile-logo-exhibition {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 19%;
        text-align: center;
        background-color: #fff;
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        padding: 5px 0px;
    }

    .logo-instansi {
        width: 100%;
    }

    .container-center-mobile-description {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 45%;
        text-align: center;

        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 48px;
        text-align: center;

        /* Pan RB Primary */

        color: #b42b2d;
        background-color: #fff;
        width: 60%;
        padding: 5px;
        border-radius: 10px;
    }

    .arrow-navigation-exhibition-mobile {
        display: flex;
        flex-direction: row;
        width: 100%;
        position: absolute;
        bottom: 25%;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        align-items: center;
    }

    .arrow-navigation-exhibition-mobile .slider-left {
        width: 46px;
        height: 46px;
        cursor: pointer;
    }

    .arrow-navigation-exhibition-mobile .slider-right {
        width: 46px;
        height: 46px;
        cursor: pointer;
    }
}
