.banner-kiri {
    position: absolute;
    left: 17%;
    top: 20%;
    height: 20%;
    width: 20%;
}

.banner-kanan {
    position: absolute;
    right: 17%;
    top: 20%;
    height: 20%;
    width: 20%;
}

.AnimationRepsetionistMale {
    position: absolute;
    top: 66%;
    width: 5%;
    left: 38%;
}

.AnimationRepsetionistMaleMobile {
    position: absolute;
    top: 60%;
    width: 25%;
    left: 0%;
}
.AnimationRepsetionistFemaleMobile {
    position: absolute;
    top: 60%;
    width: 25%;
    right: 0%;
}

.AnimationRepsetionistFemale {
    position: absolute;
    top: 66%;
    width: 5%;
    right: 38%;
}

.AnimationGroupOne {
    position: absolute;
    bottom: 1%;
    width: 9%;
    right: 5%;
}

.AnimationGroupTwo {
    position: absolute;
    top: 62%;
    width: 12%;
    right: 26%;
}

.AnimationGroupThree {
    position: absolute;
    top: 55%;
    width: 6%;
    right: 12%;
}
.AnimationGroupFour {
    position: absolute;
    top: 58%;
    width: 5%;
    right: 30%;
}
.AnimationGroupFive {
    position: absolute;
    top: 58%;
    width: 5%;
    left: 14%;
    transform: rotateY(180deg);
}

.AnimationGroupSix {
    position: absolute;
    bottom: 3%;
    width: 10%;
    left: 7%;
    transform: rotateY(180deg);
}

$white: #ffffff;
$title-color: #47403e;
$grey: #f6f6f6;
$primary: #b42b2d;
$primary-disabled: #f89092;
$border-color: #c2c2c2;

.form-control {
    margin-bottom: 8px;
}
.form-label {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #4f4f4f;
    margin-bottom: 2px;
}

.form-error {
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 15px;
    color: $primary;
    margin-bottom: 2px;
}
.form-control-title {
    margin-bottom: 8px;
}
.form-label-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.19305px;
    color: $primary;
}

.form-label-msg {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    /* or 156% */

    display: flex;
    align-items: center;
    text-align: center;
    color: #4f4f4f;
}

.form-control-close {
    text-align: right;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}
input[type="text"] {
    width: 100%;
    height: 34.75px;
    background: $white;
    border: 1px solid $border-color;
    box-sizing: border-box;
    border-radius: 4.72201px;
    padding: 0px 10px;
    z-index: 9999;
}

input[type="number"] {
    width: 100%;
    height: 34.75px;
    background: $white;
    border: 1px solid $border-color;
    box-sizing: border-box;
    border-radius: 4.72201px;
    padding: 0px 10px;
    z-index: 9999;
}

@mixin button {
    margin-top: 10px;
    width: 100%;
    height: 45px;
    color: $white;
    border: 1px solid $border-color;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 0px 10px;
    font-weight: bold;
}

input[type="submit"].lg-button {
    @include button();
    background: $primary;
    width: 100%;
}

input[type="submit"].lg-button-non-active {
    @include button();
    background: $primary-disabled;
    width: 100%;
}

input[type="submit"].sm-button {
    @include button();
    background: $primary;
    width: 100%;
}

input[type="submit"].sm-button-non-active {
    @include button();
    background: $primary-disabled;
    width: 100%;
}

@mixin secondary-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: #ffffff;
    /* Pan RB Primary */
    border: 1px solid #b42b2d;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    cursor: pointer !important;
    width: 141px;
    height: 40px;
    z-index: 99;
    color: #b42b2d;
}

@mixin positioning-home-secondary-button-left {
    position: absolute;
    left: 15%;
    top: 65%;
}

@mixin positioning-home-secondary-button-right {
    position: absolute;
    right: 15%;
    top: 66%;
}

.secondary-button-left {
    @include secondary-button();
    @include positioning-home-secondary-button-left();
}

.secondary-button-right {
    @include secondary-button();
    @include positioning-home-secondary-button-right();
}

.secondary-button-center {
    @include secondary-button();
    bottom: calc(0px + 40%);
    position: absolute;
    cursor: pointer;
    z-index: 99;
}

.secondary-button-exhibition-hall {
    @include secondary-button();
    bottom: calc(0px + 20%);
    position: absolute;
    cursor: pointer;
    z-index: 99;
}

.secondary-button-zoominar {
    @include secondary-button();
    bottom: calc(0px + 12%);
    position: absolute;
    cursor: pointer;
    z-index: 99;
}

.secondary-button-zoominar-mobile {
    @include secondary-button();
    top: calc(0px + 20%);
    position: absolute;
    cursor: pointer;
    z-index: 99;
}

.secondary-button-beranda-mobile {
    @include secondary-button();
    top: calc(0px + 12%);
    position: absolute;
    cursor: pointer;
    z-index: 99;
}

.container-input {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.container-input .kode-negara {
    width: 15%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #f3f3f3;
    margin: 0px 4px;
    height: 37.07px;
    font-size: 12px;
    font-weight: 500;
}

.container-input .inputan {
    width: 85%;
}
