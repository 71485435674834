.all-screen-zoominar {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 9999;
    bottom: 6%;
}

.container-footer-center {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
}
.container-footer-website {
    height: 55px;
    width: 40vw;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    justify-content: space-between;
    bottom: 0;
    position: absolute;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    margin: auto;
}

.container-footer-website .item {
    padding: 10px;
}

.container-footer-website .item-left {
    padding: 10px 0px 10px 50px;
}

.container-footer-website .item-right {
    padding: 10px 50px 10px 0px;
}

.container-footer-website .item-left .pengunjung {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.container-footer-website .item .sound-container {
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.container-footer-website .item .sound-container .sound {
    margin-right: 4px;
}

.container-footer-website .item-left .visitor {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: #b42b2d;
}

.container-footer-website .item-right .kontak-kami {
    background: #ffffff;
    /* Pan RB Primary */

    border: 1px solid #b42b2d;
    box-sizing: border-box;
    border-radius: 20px;
    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 0px 8px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    text-align: center;
    white-space: nowrap;

    /* Pan RB Primary */

    color: #b42b2d;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
    padding: 4px 10px;
    cursor: pointer;
}
