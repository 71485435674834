.homepage {
    background-image: url(./img/home.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh !important;
}

.homepage-mobile {
    background-image: url(./img/home-mobile.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh !important;
}

.exhibition {
    background-image: url(./img/exhibition.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh !important;
}

.exhibition-mobile {
    background-image: url(./img/exhibition-mobile.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh !important;
}

.exhibitionDetail {
    background-image: url(./img/exhibition.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh !important;
}


.zoominar {
    background-image: url(./img/zoominar.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh !important;
}

.zoominar-mobile {
    background-image: url(./img/zoominar-mobile.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}
@media not all and (min-resolution:.001dpcm) { @media {
  .zoominar-mobile {
    height: -webkit-fill-available;
  }
}}

.booth {
    background-image: url(./img/booth.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh !important;
}

.booth-mobile {
    background-image: url(./img/booth-mobile.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh !important;
}
