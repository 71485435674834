// Galaxy Fold
@media only screen and (min-device-width: 280px) and (max-device-width: 320px) {
    .container-center {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        align-items: center;
        align-content: center;
        bottom: 35%;
        position: absolute;
        cursor: pointer;
    }

    .bukutamu-ucapan {
        font-size: 11px;
    }

    .bukutamu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px;
        background: #ffffff;
        /* Pan RB Primary */
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        cursor: pointer !important;
        width: 141px;
        color: #b42b2d;
        padding: 10px;
        margin: 5px;
    }
}

@media only screen and (min-device-width: 321px) and (max-device-width: 360px) {
    .container-center {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        align-items: center;
        align-content: center;
        bottom: 35%;
        position: absolute;
        cursor: pointer;
    }

    .bukutamu-ucapan {
        font-size: 11px;
    }

    .bukutamu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px;
        background: #ffffff;
        /* Pan RB Primary */
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        cursor: pointer !important;
        width: 70%;
        color: #b42b2d;
    }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 400px) {
    .container-center {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        align-items: center;
        align-content: center;
        bottom: 35%;
        position: absolute;
        cursor: pointer;
    }
    .bukutamu-ucapan {
        font-size: 11px;
    }

    .bukutamu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px;
        background: #ffffff;
        /* Pan RB Primary */
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        cursor: pointer !important;
        width: 70%;
        padding: 10px;
        margin: 5px;
        color: #b42b2d;
    }
}

@media only screen and (device-width: 375px) and (min-device-height: 800px) {
    .container-center {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        align-items: center;
        align-content: center;
        bottom: 38%;
        position: absolute;
        cursor: pointer;
    }
    .bukutamu-ucapan {
        font-size: 16px;
    }

    .bukutamu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px;
        background: #ffffff;
        /* Pan RB Primary */
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        cursor: pointer !important;
        width: 141px;
        padding: 10px;
        color: #b42b2d;
    }
}

@media only screen and (min-device-width: 400px) and (max-device-width: 430px) {
    .container-center {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        align-items: center;
        align-content: center;
        bottom: 38%;
        position: absolute;
        cursor: pointer;
    }

    .bukutamu-ucapan {
        font-size: 14px;
    }

    .bukutamu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px;
        background: #ffffff;
        /* Pan RB Primary */
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        cursor: pointer !important;
        width: 141px;
        padding: 10px;
        margin: 5px;
        color: #b42b2d;
    }
}

@media only screen and (min-device-width: 431px) and (max-device-width: 540px) {
    .container-center {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        align-items: center;
        align-content: center;
        bottom: 38%;
        position: absolute;
        cursor: pointer;
    }

    .bukutamu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px;
        background: #ffffff;
        /* Pan RB Primary */
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        cursor: pointer !important;
        width: 141px;
        color: #b42b2d;
    }
}

@media only screen and (min-device-width: 541px) and (max-device-width: 767px) {
    .container-center {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        align-items: center;
        align-content: center;
        bottom: 38%;
        position: absolute;
        cursor: pointer;
    }

    .bukutamu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px;
        background: #ffffff;
        /* Pan RB Primary */
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        cursor: pointer !important;
        width: 141px;
        color: #b42b2d;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .container-center {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        align-items: center;
        align-content: center;
        bottom: 38%;
        position: absolute;
        cursor: pointer;
    }
    .bukutamu-ucapan {
        font-size: 16px;
    }

    .bukutamu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px;
        background: #ffffff;
        /* Pan RB Primary */
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        cursor: pointer !important;
        color: #b42b2d;
        font-size: 24px;
        padding: 10px;
        width: 70%;
        margin: 10px;
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
    .container-center {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        align-items: center;
        align-content: center;
        bottom: 36%;
        position: absolute;
        cursor: pointer;
    }
    .bukutamu-ucapan {
        font-size: 32px;
    }

    .bukutamu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px;
        background: #ffffff;
        /* Pan RB Primary */
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        cursor: pointer !important;
        color: #b42b2d;
        font-size: 32px;
        padding: 15px;
        width: 50%;
        margin: 10px;
    }
}
