.centering-action-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    bottom: 0px;
    position: absolute;
    margin: auto;
    width: 100%;
    cursor: pointer;
}

.action {
    padding: 5px;
}
