.marquee {
  height: 40px;
  overflow: hidden;
  position: absolute;
  background: #fefefe;
  color: #b42b2d;
  bottom: 0;
  z-index: 9999;
  width: 100vw;
}

.zoominar-container {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  .video-playlist {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
    border-radius: 2.75px;
    width: 176px;
    position: absolute;
    left: 1%;
    top: 25%;
    .playlist-title {
      font-family: Poppins;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
      background: #ffffff;
      box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
      padding: 3px 14px;
    }
    .playlist-video {
      overflow-y: auto;
      padding: 0px 8px;
      max-height: 360px;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      .video-item {
        background: #ffffff;
        padding: 0px 8px;
        margin-top: 4px;
        .video-title {
          font-size: 11px;
          line-height: 13px;
          padding: 4px 0px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .video-img {
          img {
            width: 100%;
          }
        }
        &:hover {
          cursor: pointer;
          .video-title {
            white-space: normal;
            overflow: auto;
            text-overflow: ellipsis;
          }
        }
      }
      .video-item.active {
        border: 1px solid #b42b2d;
      }
    }
  }
  .zoominar-content {
    width: 100%;
    max-width: 720px;
    position: absolute;
    top: 25%;
    #video-frame {
      position: relative;
      background: #ffffff;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      height: 0px;
      width: 100%;
      padding-top: 50%;
      top: 10%;
      .video-frame-wrapper {
        position: absolute;
        top: 12px;
        left: 12px;
        height: 100%;
        width: 100%;
        iframe {
          width: 96%;
          height: 92%;
        }
      }
    }
  }
}

.zoominar-container-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .video-playlist {
    background: rgba(255, 255, 255, 0.75);
    box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
    border-radius: 2.75px;
    width: 100%;
    .playlist-title {
      font-family: Poppins;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
      background: #ffffff;
      box-shadow: 0px 2.75px 2.75px rgba(0, 0, 0, 0.1);
      padding: 3px 14px;
    }
    .playlist-video {
      overflow-y: auto;
      padding: 0px 8px;
      max-height: 70vh;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      .video-item {
        background: #ffffff;
        padding: 0px 8px;
        margin-top: 8px;
        .video-title {
          font-size: 11px;
          line-height: 13px;
          padding: 4px 0px;
        }
        .video-img {
          img {
            width: 100%;
          }
        }
        &:hover {
          cursor: pointer;
        }
      }
      .video-item.active {
        border: 1px solid #b42b2d;
      }
    }
  }
  .zoominar-content {
    width: 100%;
    max-width: 720px;
    #video-frame {
      position: relative;
      background: #ffffff;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      height: 0px;
      width: 100%;
      padding-top: 50%;
      .video-frame-wrapper {
        position: absolute;
        top: 12px;
        left: 12px;
        height: 100%;
        width: 100%;
        iframe {
          width: 93%;
          height: 89%;
        }
      }
    }
  }
}

.container-footer-mobile.move-top {
  bottom: 40px;
}

.form-control-close.floating {
  position: relative;
  .form-label-close {
    position: absolute;
    right: 12px;
  }
}

.no-padding-top {
  padding-top: 0px !important;
}

.marquee p {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 40px;
  text-align: center;
  transform: translateX(100%);
  // -webkit-animation: scroll-left 5s linear infinite;
  // animation: scroll-left 20s linear infinite;
}

.marquee marquee {
  margin-top: 8px;
  span {
    margin-right: 64px;
  }
}

// .marquee p marquee span a {
//     text-decoration: none;
//     color: #b42b2d;
// }
//
// .marquee p span {
//     margin-left: 45px;
// }
//
// .marquee p span a {
//     text-decoration: none;
//     color: #b42b2d;
// }

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
