@media only screen and (min-device-width: 280px) and (max-device-width: 320px) {
    .button-homepage-mobile {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;

        background: #ffffff;
        border: 1px solid #b42b2d;
        color: #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 16px;
        width: 40%;
    }

    .button-group-homepage-mobile {
        display: flex;
        flex-direction: row;
        padding: 0px;
        text-align: center;
        justify-content: center;
        position: absolute;
        width: 100vw;
        left: 0px;
        right: 0px;
        bottom: 10%;
        font-size: 12px;
        font-weight: bold;
    }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 400px) {
    .button-homepage-mobile {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;

        background: #ffffff;
        border: 1px solid #b42b2d;
        color: #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 16px;
        width: 40%;
    }

    .button-group-homepage-mobile {
        display: flex;
        flex-direction: row;
        padding: 0px;
        text-align: center;
        justify-content: center;
        position: absolute;
        width: 100vw;
        left: 0px;
        right: 0px;
        bottom: 10%;
        font-size: 12px;
        font-weight: bold;
    }
}

// IPHONE X
@media only screen and (device-width: 375px) and (min-device-height: 800px) {
    .button-homepage-mobile {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;

        background: #ffffff;
        border: 1px solid #b42b2d;
        color: #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 16px;
        width: 40%;
    }

    .button-group-homepage-mobile {
        display: flex;
        flex-direction: row;
        padding: 0px;
        text-align: center;
        justify-content: center;
        position: absolute;
        width: 100vw;
        left: 0px;
        right: 0px;
        bottom: 10%;
        font-size: 16px;
        font-weight: bold;
    }
}

@media only screen and (min-device-width: 400px) and (max-device-width: 430px) {
    .button-homepage-mobile {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;

        background: #ffffff;
        border: 1px solid #b42b2d;
        color: #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 16px;
        width: 40%;
    }

    .button-group-homepage-mobile {
        display: flex;
        flex-direction: row;
        padding: 0px;
        text-align: center;
        justify-content: center;
        position: absolute;
        width: 100vw;
        left: 0px;
        right: 0px;
        bottom: 10%;
        font-size: 12px;
        font-weight: bold;
    }
}

@media only screen and (min-device-width: 431px) and (max-device-width: 540px) {
    .button-homepage-mobile {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;

        background: #ffffff;
        border: 1px solid #b42b2d;
        color: #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 16px;
        width: 40%;
    }

    .button-group-homepage-mobile {
        display: flex;
        flex-direction: row;
        padding: 0px;
        text-align: center;
        justify-content: center;
        position: absolute;
        width: 100vw;
        left: 0px;
        right: 0px;
        bottom: 10%;
        font-size: 12px;
        font-weight: bold;
    }
}

@media only screen and (min-device-width: 541px) and (max-device-width: 767px) {
    .button-homepage-mobile {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;

        background: #ffffff;
        border: 1px solid #b42b2d;
        color: #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 16px;
        width: 40%;
    }

    .button-group-homepage-mobile {
        display: flex;
        flex-direction: row;
        padding: 0px;
        text-align: center;
        justify-content: center;
        position: absolute;
        width: 100vw;
        left: 0px;
        right: 0px;
        bottom: 10%;
        font-size: 12px;
        font-weight: bold;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .button-homepage-mobile {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 10px;

        background: #ffffff;
        border: 1px solid #b42b2d;
        color: #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 16px;
        width: 40%;
    }

    .button-group-homepage-mobile {
        display: flex;
        flex-direction: row;
        text-align: center;
        justify-content: center;
        position: absolute;
        width: 100vw;
        left: 0px;
        right: 0px;
        bottom: 10%;
        font-size: 24px;
        font-weight: bold;
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
    .button-homepage-mobile {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;

        background: #ffffff;
        border: 1px solid #b42b2d;
        color: #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 16px;
        width: 30%;
    }

    .button-group-homepage-mobile {
        display: flex;
        flex-direction: row;
        padding: 0px;
        text-align: center;
        justify-content: center;
        position: absolute;
        width: 100vw;
        left: 0px;
        right: 0px;
        bottom: 6%;
        font-size: 32px;
        font-weight: bold;
    }
}
