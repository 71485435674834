.button-exhibition-mobile {
    display: flex;
    flex-direction: row;
    left: 0;
    right: 0;
    background-color: yellow;
    width: 50%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    background: #ffffff;
    font-size: 11px;
    border: 1px solid #b42b2d;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 10px;
    color: #b42b2d;
    position: absolute;
    margin-top: 10px;
}

.button-exhibition-mobile-zoominar {
    display: flex;
    flex-direction: row;
    left: 0;
    right: 0;
    background-color: yellow;
    width: 50%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    background: #ffffff;
    font-size: 11px;
    border: 1px solid #b42b2d;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 10px;
    color: #b42b2d;
    position: absolute;
    margin-top: 35%;
}

@media only screen and (min-device-width: 280px) and (max-device-width: 359px) {
    .button-exhibition-mobile-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 40%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 5px 0px;
        color: #b42b2d;
        position: absolute;
        top: 10%;
    }

    .button-exhibition-mobile-zoominar {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 40%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 5px 0px;
        color: #b42b2d;
        position: absolute;
        margin-top: 35%;
    }

    .icon-exhibition-hall-mobile {
        width: 14px;
        height: 14px;
    }

    .button-logo-and-text-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 45%;
        justify-content: left;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        padding: 5px 0px;
        color: #b42b2d;
        font-size: 11px;
    }

    .nama-instansi-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 60%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 5.07205px;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 9px;
        line-height: 13px;
        padding: 6px;
        text-align: center;
        color: #b42b2d;
        position: absolute;
        top: 18%;
    }
    .description-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 55%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 12%;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 8px;
        padding: 6px;
        text-align: center;
        color: #fff;
        position: absolute;
        top: 28%;
    }

    .info-exhibition-detail-mobile {
        display: flex;
        flex-direction: column;
        left: 0;
        right: 0;
        width: 80%;
        font-size: 11px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        padding: 10px 0px;
        position: absolute;
        bottom: 10%;
    }

    .button-logo-and-text-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 45%;
        justify-content: left;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        padding: 6px;
        margin-top: 2%;
        font-size: 8px;
        color: #b42b2d;
    }

    .info {
        font-size: 8px;
    }

    .button-logo-and-text2 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 60%;
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        margin-top: 2%;
        padding: 5px 5px;
        color: #2f80ed;
        margin-bottom: 4px;
    }

    .button-logo-and-text-icon-mobile img {
        width: 14px;
    }
    .bergabungdizoom {
        font-size: 9px;
    }

    .button-logo-and-text3 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 60%;
        padding: 5px 5px;
        font-size: 8px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        color: #b42b2d;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 359px) {
    .button-exhibition-mobile-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 5px 0px;
        color: #b42b2d;
        position: absolute;
        top: 11%;
    }

    .button-exhibition-mobile-zoominar {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 5px 0px;
        color: #b42b2d;
        position: absolute;
        top: 0%;
    }

    .icon-exhibition-hall-mobile {
        width: 14px;
        height: 14px;
    }

    .button-logo-and-text-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 45%;
        justify-content: left;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        padding: 5px 0px;
        color: #b42b2d;
        font-size: 11px;
    }

    .nama-instansi-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 60%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 5.07205px;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 9px;
        line-height: 13px;
        padding: 6px;
        text-align: center;
        color: #b42b2d;
        position: absolute;
        top: 18%;
    }
    .description-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 55%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 12%;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 8px;
        padding: 6px;
        text-align: center;
        color: #fff;
        position: absolute;
        top: 26.5%;
    }

    .info-exhibition-detail-mobile {
        display: flex;
        flex-direction: column;
        left: 0;
        right: 0;
        width: 80%;
        font-size: 11px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        padding: 10px 0px;
        position: absolute;
        bottom: 10%;
    }

    .button-logo-and-text-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 45%;
        justify-content: left;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        padding: 6px;
        margin-top: 2%;
        font-size: 8px;
        color: #b42b2d;
    }

    .info {
        font-size: 8px;
    }

    .button-logo-and-text2 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 50%;
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        margin-top: 2%;
        padding: 5px 5px;
        color: #2f80ed;
        margin-bottom: 4px;
    }

    .button-logo-and-text-icon-mobile img {
        width: 14px;
    }
    .bergabungdizoom {
        font-size: 9px;
    }

    .button-logo-and-text3 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 50%;
        padding: 5px 5px;
        font-size: 8px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        color: #b42b2d;
    }
}

@media only screen and (min-device-width: 400px) and (max-device-width: 460px) {
    .button-exhibition-mobile-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 20%;
    }
    .nama-instansi-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 5.07205px;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        padding: 10px;
        text-align: center;
        line-height: 18px;
        color: #b42b2d;
        position: absolute;
        top: 13%;
    }
    .description-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 55%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 8px;
        padding: 10px;
        text-align: center;
        color: #fff;
    }
}

@media only screen and (min-device-width: 380px) and (max-device-width: 399px) {
    .button-exhibition-mobile-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 20%;
    }
    .nama-instansi-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 5.07205px;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        padding: 10px;
        text-align: center;
        color: #b42b2d;
        position: absolute;
        top: 20%;
    }
    .description-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 55%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;

        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 8px;
        padding: 10px;
        text-align: center;
        color: #fff;
        position: absolute;
        top: 34%;
    }
    .icon-exhibition-hall-mobile {
        width: 16px;
        height: 16px;
    }
    .button-logo-and-text-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 45%;
        justify-content: left;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        padding: 10px 10px;
        color: #b42b2d;
        font-size: 11px;
        margin-top: 5px;
    }

    .info-exhibition-detail-mobile {
        display: flex;
        flex-direction: column;
        left: 0;
        right: 0;
        width: 80%;
        font-size: 11px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        padding: 10px 0px;
        position: absolute;
        bottom: 13%;
    }

    .info {
        font-size: 11px;
    }

    .button-logo-and-text2 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 50%;
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        margin-top: 2%;
        padding: 10px 10px;
        color: #2f80ed;
        margin-bottom: 4px;
    }

    .button-logo-and-text-icon-mobile img {
        width: 16px;
    }
    .bergabungdizoom {
        font-size: 11px;
    }

    .button-logo-and-text3 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 50%;
        padding: 10px 10px;
        font-size: 11px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        color: #b42b2d;
    }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 379px) {
    .button-exhibition-mobile-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 20%;
    }
    .nama-instansi-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 60%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 5.07205px;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 18px;
        padding: 10px;
        text-align: center;
        color: #b42b2d;
        position: absolute;
        top: 19%;
    }
    .description-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 55%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 8px;
        padding: 10px;
        text-align: center;
        color: #fff;
        position: absolute;
        top: 33%;
    }
    .icon-exhibition-hall-mobile {
        width: 14px;
        height: 14px;
    }
    .button-logo-and-text-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 45%;
        justify-content: left;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        padding: 5px 5px;
        color: #b42b2d;
        font-size: 11px;
        margin-top: 5px;
    }

    .info-exhibition-detail-mobile {
        display: flex;
        flex-direction: column;
        left: 0;
        right: 0;
        width: 80%;
        font-size: 11px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        padding: 10px 0px;
        position: absolute;
        bottom: 10%;
    }

    .button-logo-and-text-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 45%;
        justify-content: left;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        padding: 6px;
        margin-top: 2%;
        font-size: 11px;
        color: #b42b2d;
    }

    .info {
        font-size: 11px;
    }

    .button-logo-and-text2 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 50%;
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        margin-top: 2%;
        padding: 5px 5px;
        color: #2f80ed;
        margin-bottom: 4px;
    }

    .button-logo-and-text-icon-mobile img {
        width: 14px;
    }
    .bergabungdizoom {
        font-size: 11px;
    }

    .button-logo-and-text3 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 50%;
        padding: 5px 5px;
        font-size: 11px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        color: #b42b2d;
    }
}

// Iphone x
@media only screen and (device-width: 375px) and (min-device-height: 812px) {
    .button-exhibition-mobile-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 20%;
    }
    .nama-instansi-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 60%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 5.07205px;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 18px;
        padding: 10px;
        text-align: center;
        color: #b42b2d;
        position: absolute;
        top: 20%;
    }
    .description-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 55%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 8px;
        padding: 10px;
        text-align: center;
        color: #fff;
        position: absolute;
        top: 33%;
    }

    .icon-exhibition-hall-mobile {
        width: 16px;
        height: 16px;
    }
    .button-logo-and-text-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 45%;
        justify-content: left;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        padding: 10px 10px;
        color: #b42b2d;
        font-size: 11px;
        margin-top: 5px;
    }

    .info-exhibition-detail-mobile {
        display: flex;
        flex-direction: column;
        left: 0;
        right: 0;
        width: 80%;
        font-size: 11px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        padding: 10px 0px;
        position: absolute;
        bottom: 11%;
    }

    .info {
        font-size: 11px;
    }

    .button-logo-and-text2 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 50%;
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        margin-top: 2%;
        padding: 10px 10px;
        color: #2f80ed;
        margin-bottom: 4px;
    }

    .button-logo-and-text-icon-mobile img {
        width: 16px;
    }
    .bergabungdizoom {
        font-size: 11px;
    }

    .button-logo-and-text3 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 50%;
        padding: 10px 10px;
        font-size: 11px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        color: #b42b2d;
    }
}

// Google Pixel 2
@media only screen and (min-device-width: 411px) and (min-device-height: 730px) {
    .button-exhibition-mobile-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 20%;
    }

    .nama-instansi-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 60%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 5.07205px;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 18px;
        padding: 10px;
        text-align: center;
        color: #b42b2d;
        position: absolute;
        top: 20%;
    }
    .description-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 55%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        padding: 10px;
        text-align: center;
        color: #fff;
        position: absolute;
        top: 33%;
    }

    .icon-exhibition-hall-mobile {
        width: 16px;
        height: 16px;
    }
    .button-logo-and-text-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 45%;
        justify-content: left;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        padding: 10px 10px;
        color: #b42b2d;
        font-size: 11px;
        margin-top: 5px;
    }

    .info-exhibition-detail-mobile {
        display: flex;
        flex-direction: column;
        left: 0;
        right: 0;
        width: 80%;
        font-size: 11px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        padding: 10px 0px;
        position: absolute;
        bottom: 11%;
    }

    .info {
        font-size: 11px;
    }

    .button-logo-and-text2 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 50%;
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        margin-top: 2%;
        padding: 10px 10px;
        color: #2f80ed;
        margin-bottom: 4px;
    }

    .button-logo-and-text-icon-mobile img {
        width: 16px;
    }
    .bergabungdizoom {
        font-size: 11px;
    }

    .button-logo-and-text3 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 50%;
        padding: 10px 10px;
        font-size: 11px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        color: #b42b2d;
    }
}

// Google Pixel 2 XL
@media only screen and (min-device-width: 411px) and (min-device-height: 820px) {
    .button-exhibition-mobile-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 11px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 20%;
    }
    .nama-instansi-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 60%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 5.07205px;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 18px;
        padding: 10px;
        text-align: center;
        color: #b42b2d;
        position: absolute;
        top: 20%;
    }
    .description-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 55%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        padding: 10px;
        text-align: center;
        color: #fff;
        position: absolute;
        top: 33%;
    }
    .icon-exhibition-hall-mobile {
        width: 16px;
        height: 16px;
    }
    .button-logo-and-text-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 45%;
        justify-content: left;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        padding: 10px 10px;
        color: #b42b2d;
        font-size: 11px;
        margin-top: 5px;
    }

    .info-exhibition-detail-mobile {
        display: flex;
        flex-direction: column;
        left: 0;
        right: 0;
        width: 80%;
        font-size: 11px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        padding: 10px 0px;
        position: absolute;
        bottom: 10%;
    }

    .info {
        font-size: 11px;
    }

    .button-logo-and-text2 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 50%;
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        margin-top: 2%;
        padding: 10px 10px;
        color: #2f80ed;
        margin-bottom: 4px;
    }

    .button-logo-and-text-icon-mobile img {
        width: 16px;
    }
    .bergabungdizoom {
        font-size: 11px;
    }

    .button-logo-and-text3 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 50%;
        padding: 10px 10px;
        font-size: 11px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        color: #b42b2d;
    }
}

// IPAD
@media only screen and (min-device-width: 768px) and (min-device-height: 1024px) {
    .button-exhibition-mobile-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 25%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 24px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 10%;
    }

    .button-exhibition-mobile-zoominar {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 25%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 24px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 20%;
    }
    .nama-instansi-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 5.07205px;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        padding: 10px;
        text-align: center;
        color: #b42b2d;
        position: absolute;
        top: 15%;
    }
    .description-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 55%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        padding: 10px;
        text-align: center;
        color: #fff;
        position: absolute;
        top: 30%;
    }

    .button-logo-and-text-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 40%;
        justify-content: left;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        padding: 10px 10px;
        color: #b42b2d;
        font-size: 24px;
        margin-top: 10px;
    }

    .info-exhibition-detail-mobile {
        display: flex;
        flex-direction: column;
        left: 0;
        right: 0;
        width: 80%;
        font-size: 24px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        padding: 10px 0px;
        position: absolute;
        bottom: 7%;
    }

    .info {
        font-size: 24px;
    }

    .button-logo-and-text2 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 55%;
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        margin-top: 2%;
        padding: 10px 10px;
        color: #2f80ed;
        margin-bottom: 15px;
    }

    .button-logo-and-text-icon-mobile img {
        width: 32px;
        height: 32px;
        margin: 0px 10px;
    }
    .button-logo-and-text-icon-mobile-video img {
        width: 32px;
    }
    .bergabungdizoom {
        font-size: 24px;
    }

    .button-logo-and-text3 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 55%;
        padding: 10px 10px;
        font-size: 24px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        color: #b42b2d;
    }
}

// IPAD PRO
@media only screen and (min-device-width: 1024px) and (min-device-height: 1366px) {
    .button-exhibition-mobile-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 25%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 32px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 10%;
    }

    .button-exhibition-mobile-zoominar {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 25%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 32px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 20%;
    }

    .nama-instansi-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 5.07205px;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 48px;
        padding: 20px;
        text-align: center;
        color: #b42b2d;
        position: absolute;
        top: 15%;
    }
    .description-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 55%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        padding: 10px;
        text-align: center;
        color: #fff;
        position: absolute;
        top: 30%;
    }

    .button-logo-and-text-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 40%;
        justify-content: left;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        padding: 10px 10px;
        color: #b42b2d;
        font-size: 32px;
        margin-top: 20px;
    }

    .info-exhibition-detail-mobile {
        display: flex;
        flex-direction: column;
        left: 0;
        right: 0;
        width: 80%;
        font-size: 22px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        padding: 10px 0px;
        position: absolute;
        bottom: 8%;
    }

    .info {
        font-size: 32px;
    }

    .button-logo-and-text2 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 55%;
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        margin-top: 2%;
        padding: 10px 10px;
        color: #2f80ed;
        margin-bottom: 15px;
    }

    .button-logo-and-text-icon-mobile img {
        width: 42px;
        height: 42px;
        margin: 0px 10px;
    }
    .button-logo-and-text-icon-mobile-video img {
        width: 42px;
    }
    .bergabungdizoom {
        font-size: 32px;
    }

    .button-logo-and-text3 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 55%;
        padding: 10px 10px;
        font-size: 32px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        color: #b42b2d;
    }
}

// IPAD PRO
@media only screen and (min-device-width: 1024px) and (device-height: 600px) {
    .button-exhibition-mobile-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 25%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 24px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 10%;
    }

    .button-exhibition-mobile-zoominar {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 25%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 24px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 10px;
        color: #b42b2d;
        position: absolute;
        margin-top: 20%;
    }

    .nama-instansi-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 5.07205px;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        padding: 10px;
        text-align: center;
        color: #b42b2d;
        position: absolute;
        top: 18%;
    }
    .description-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 55%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        padding: 10px;
        text-align: center;
        color: #fff;
        position: absolute;
        top: 30%;
    }

    .button-logo-and-text-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 30%;
        justify-content: left;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        padding: 10px 10px;
        color: #b42b2d;
        font-size: 24px;
        margin-top: 20px;
    }

    .info-exhibition-detail-mobile {
        display: flex;
        flex-direction: column;
        left: 0;
        right: 0;
        width: 80%;
        font-size: 22px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        padding: 10px 0px;
        position: absolute;
        bottom: 11%;
    }

    .info {
        font-size: 22px;
    }

    .button-logo-and-text2 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 35%;
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        margin-top: 2%;
        padding: 10px 10px;
        color: #2f80ed;
        margin-bottom: 15px;
    }

    .button-logo-and-text-icon-mobile img {
        width: 32px;
        height: 32px;
    }
    .button-logo-and-text-icon-mobile-video img {
        width: 32px;
    }
    .bergabungdizoom {
        font-size: 22px;
    }

    .button-logo-and-text3 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 35%;
        padding: 10px 10px;
        font-size: 22px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        color: #b42b2d;
    }
}

// SURFACE DUO
@media only screen and (device-width: 540px) and (max-device-width: 720px) {
    .button-exhibition-mobile-beranda {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 25%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 16px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 5px;
        color: #b42b2d;
        position: absolute;
        margin-top: 12%;
    }

    .button-exhibition-mobile-zoominar {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        background-color: yellow;
        width: 25%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        font-size: 16px;
        border: 1px solid #b42b2d;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 5px;
        color: #b42b2d;
        position: absolute;
        margin-top: 20%;
    }
    .nama-instansi-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 50%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 5.07205px;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        padding: 10px;
        text-align: center;
        color: #b42b2d;
        position: absolute;
        top: 18%;
    }
    .description-exhibition-detail-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 55%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        padding: 10px;
        text-align: center;
        color: #fff;
        position: absolute;
        top: 28%;
    }

    .button-logo-and-text-mobile {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 35%;
        justify-content: left;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        padding: 10px 10px;
        color: #b42b2d;
        font-size: 12px;
        margin-top: 5px;
    }

    .info-exhibition-detail-mobile {
        display: flex;
        flex-direction: column;
        left: 0;
        right: 0;
        width: 80%;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        padding: 10px 0px;
        position: absolute;
        bottom: 5%;
    }

    .info {
        font-size: 12px;
    }

    .button-logo-and-text2 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 35%;
        justify-content: space-around;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        margin-top: 2%;
        padding: 10px 10px;
        color: #2f80ed;
        margin-bottom: 15px;
    }

    .button-logo-and-text-icon-mobile img {
        width: 24px;
        height: 24px;
    }
    .button-logo-and-text-icon-mobile-video img {
        width: 24px;
    }
    .bergabungdizoom {
        font-size: 12px;
    }

    .button-logo-and-text3 {
        display: flex;
        flex-direction: row;
        left: 0;
        right: 0;
        width: 35%;
        padding: 10px 10px;
        font-size: 12px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background: #ffffff;
        box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        border: 0.922965px solid #b42b2d;
        color: #b42b2d;
    }
}

.tengah {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button-logo-and-text-icon-mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
    font-size: 11px;
}
.button-logo-and-text {
    display: flex;
    flex-direction: row;
    left: 0;
    right: 0;
    width: 50%;
    justify-content: left;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background: #ffffff;
    box-shadow: 0px 2.53603px 2.53603px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    padding: 10px;
    margin-top: 2%;
    color: #b42b2d;
}

.button-logo-and-text-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
}

.info-exhibition-detail-mobile,
.info {
    text-align: center;
}
