@mixin top-menu {
    height: 55px;
    width: 100vw;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    top: 0;
}

.flexing {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

@mixin logo-browser {
    height: 30px;
}

.top-menu-browser {
    @include top-menu();
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    justify-content: space-between;
}

.top-menu-logo-browser {
    @include logo-browser();
    margin-left: 40px;
}

@media only screen and (min-device-width: 280px) and (max-device-width: 320px) {
    .top-menu-logo-mobile {
        height: 30px;
    }
    .top-menu-mobile-hamburger {
        width: 40%;
    }
    .top-menu-mobile-logo {
        width: 60%;
    }

    .top-menu-mobile {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        height: 55px;
        width: 100vw;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: row;
        align-items: center;
        position: fixed;
        top: 0;
        justify-content: center;
        z-index: 9999;
    }

    .top-menu-mobile-container {
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        left: 0;
        z-index: 999999999;
        position: fixed;
    }

    .top-menu-mobile-frame {
        background-color: #fff;
        height: 100%;
        width: 70%;
        left: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .top-menu-mobile-item {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.15978px;
        color: #b42b2d;
        padding: 10px 16px;
        z-index: 999;
    }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 400px) {
    .top-menu-logo-mobile {
        height: 30px;
    }
    .top-menu-mobile-hamburger {
        width: 40%;
    }
    .top-menu-mobile-logo {
        width: 60%;
    }

    .top-menu-mobile {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        height: 55px;
        width: 100vw;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: row;
        align-items: center;
        position: fixed;
        top: 0;
        justify-content: center;
        z-index: 9999;
    }

    .top-menu-mobile-container {
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        left: 0;
        z-index: 999999999;
        position: fixed;
    }

    .top-menu-mobile-frame {
        background-color: #fff;
        height: 100%;
        width: 70%;
        left: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .top-menu-mobile-item {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.15978px;
        color: #b42b2d;
        padding: 10px 16px;
        z-index: 999;
    }
}

// IPHONE X
@media only screen and (device-width: 375px) and (min-device-height: 800px) {
    .top-menu-logo-mobile {
        height: 30px;
    }
    .top-menu-mobile-hamburger {
        width: 40%;
    }
    .top-menu-mobile-logo {
        width: 60%;
    }

    .top-menu-mobile {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        height: 55px;
        width: 100vw;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: row;
        align-items: center;
        position: fixed;
        top: 0;
        justify-content: center;
        z-index: 9999;
    }

    .top-menu-mobile-container {
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        left: 0;
        z-index: 999999999;
        position: fixed;
    }

    .top-menu-mobile-frame {
        background-color: #fff;
        height: 100%;
        width: 70%;
        left: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .top-menu-mobile-item {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.15978px;
        color: #b42b2d;
        padding: 10px 16px;
        z-index: 999;
    }
}

@media only screen and (min-device-width: 400px) and (max-device-width: 430px) {
    .top-menu-logo-mobile {
        height: 30px;
    }
    .top-menu-mobile-hamburger {
        width: 40%;
    }
    .top-menu-mobile-logo {
        width: 60%;
    }

    .top-menu-mobile {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        height: 55px;
        width: 100vw;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: row;
        align-items: center;
        position: fixed;
        top: 0;
        justify-content: center;
        z-index: 9999;
    }

    .top-menu-mobile-container {
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        left: 0;
        z-index: 999999999;
        position: fixed;
    }

    .top-menu-mobile-frame {
        background-color: #fff;
        height: 100%;
        width: 70%;
        left: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .top-menu-mobile-item {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.15978px;
        color: #b42b2d;
        padding: 10px 16px;
        z-index: 999;
    }
}

@media only screen and (min-device-width: 431px) and (max-device-width: 540px) {
    .top-menu-logo-mobile {
        height: 30px;
    }
    .top-menu-mobile-hamburger {
        width: 40%;
    }
    .top-menu-mobile-logo {
        width: 60%;
    }

    .top-menu-mobile {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        height: 55px;
        width: 100vw;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: row;
        align-items: center;
        position: fixed;
        top: 0;
        justify-content: center;
        z-index: 9999;
    }

    .top-menu-mobile-container {
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        left: 0;
        z-index: 999999999;
        position: fixed;
    }

    .top-menu-mobile-frame {
        background-color: #fff;
        height: 100%;
        width: 70%;
        left: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .top-menu-mobile-item {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.15978px;
        color: #b42b2d;
        padding: 10px 16px;
        z-index: 999;
    }
}

@media only screen and (min-device-width: 541px) and (max-device-width: 767px) {
    .top-menu-logo-mobile {
        height: 30px;
    }
    .top-menu-mobile-hamburger {
        width: 40%;
    }
    .top-menu-mobile-logo {
        width: 60%;
    }

    .top-menu-mobile {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        height: 55px;
        width: 100vw;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: row;
        align-items: center;
        position: fixed;
        top: 0;
        justify-content: center;
        z-index: 9999;
    }

    .top-menu-mobile-container {
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        left: 0;
        z-index: 999999999;
        position: fixed;
    }

    .top-menu-mobile-frame {
        background-color: #fff;
        height: 100%;
        width: 70%;
        left: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .top-menu-mobile-item {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.15978px;
        color: #b42b2d;
        padding: 10px 16px;
        z-index: 999;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .top-menu-logo-mobile {
        height: 50px;
    }
    .top-menu-mobile-hamburger {
        width: 40%;
    }
    .top-menu-mobile-logo {
        width: 60%;
    }

    .top-menu-mobile {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        height: 100px;
        width: 100vw;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: row;
        align-items: center;
        position: fixed;
        top: 0;
        justify-content: center;
        z-index: 9999;
    }

    .top-menu-mobile-container {
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        left: 0;
        z-index: 999999999;
        position: fixed;
    }

    .top-menu-mobile-frame {
        background-color: #fff;
        height: 100%;
        width: 70%;
        left: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .top-menu-mobile-item {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.15978px;
        color: #b42b2d;
        padding: 20px 16px;
        z-index: 999;
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
    .top-menu-logo-mobile {
        height: 50px;
    }
    .top-menu-mobile-hamburger {
        width: 40%;
    }
    .top-menu-mobile-logo {
        width: 60%;
    }

    .top-menu-mobile {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        height: 100px;
        width: 100vw;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: row;
        align-items: center;
        position: fixed;
        top: 0;
        justify-content: center;
        z-index: 9999;
    }

    .top-menu-mobile-container {
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        left: 0;
        z-index: 999999999;
        position: fixed;
    }

    .top-menu-mobile-frame {
        background-color: #fff;
        height: 100%;
        width: 70%;
        left: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .top-menu-mobile-item {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 18px;
        letter-spacing: 0.15978px;
        color: #b42b2d;
        padding: 24px 16px;
        z-index: 999;
    }
}
